<template>
  <c-flex flex-dir="column" w="full" class="container">
    <c-flex>
      <c-text flex="1" font-weight="500" font-size="lg">
        {{ props.date | formatDate('YYYY-MM-DD HH:mm') }}
      </c-text>
      <c-link d="flex" @click="emit('click', id)">
        <c-text color="brand.900" font-size="md">Lihat Detail</c-text>
        <c-image
          :src="require('@/assets/icon-chevron-right.svg')"
          h="24px"
          w="24px"
        />
      </c-link>
    </c-flex>
    <c-flex
      mt="10px"
      w="full"
      grid-gap="70px"
      py="15px"
      px="25px"
      border-width="1px"
      border-color="#c4c4c4"
      rounded="md"
    >
      <c-flex flex="1" flex-dir="column" grid-gap="5px">
        <CardTextLine
          :icon="require('@/assets/ic-scale-outline.svg')"
          text="Berat Badan"
          :value="`${props.weight || '-'} kg`"
        />
        <CardTextLine
          :icon="require('@/assets/ic-waist-outline.svg')"
          text="Lingkar Pinggang"
          :value="`${props.waist || '-'} cm`"
        />
        <CardTextLine
          :icon="require('@/assets/ic-brand-asana.svg')"
          text="Persen Lemak"
          :value="`${props.bodyFat || '-'} %`"
        />
        <CardTextLine
          :icon="require('@/assets/ic-muscle.svg')"
          text="Massa Otot"
          :value="`${props.muscleMass || '-'} kg`"
        />
      </c-flex>
      <c-flex flex="1" flex-dir="column" grid-gap="5px">
        <CardTextLine
          :icon="require('@/assets/ic-droplet.svg')"
          text="Cairan Tubuh"
          :value="`${props.bodyWater || '-'} %`"
        />
        <CardTextLine
          :icon="require('@/assets/ic-brand-asana.svg')"
          text="Lemak Visceral"
          :value="`${props.visceralFat || '-'} %`"
        />
        <CardTextLine
          :icon="require('@/assets/ic-bone.svg')"
          text="Massa Tulang"
          :value="`${props.boneMass || '-'} kg`"
        />
        <CardTextLine
          :icon="require('@/assets/ic-man.svg')"
          text="BMR"
          :value="props.bmr || '-'"
        />
      </c-flex>
    </c-flex>
  </c-flex>
</template>

<script >
import CardTextLine from "./card-text-line.vue";
const __sfc_main = {};
__sfc_main.props = ["id", "date", "weight", "waist", "bodyFat", "muscleMass", "bodyWater", "visceralFat", "boneMass", "bmr"];

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  return {
    props,
    emit
  };
};

__sfc_main.components = Object.assign({
  CardTextLine
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped>
.container {
  position: relative;
  border-left-width: 2px;
  border-color: #008c8155;
  padding-left: 20px;
  padding-bottom: 15px;
}

.container:last-child {
  padding-bottom: 0;
}

.container::before {
  content: " ";
  position: absolute;
  width: 27px;
  height: 27px;
  background: #008c81;
  border-radius: 50%;
  border: 5px solid white;
  left: -1px;
  top: 0;
  transform: translate(-50%);
}
</style>
