<template>
  <c-flex align-items="center">
    <c-image :src="icon" w="20px" h="20px" />
    <c-text ml="10px" flex="1" color="grey" font-size="18px">{{text}}</c-text>
    <c-text ml="10px" font-size="18px" font-weight="500">{{value}}</c-text>
  </c-flex>
</template>

<script>
export default {
  name: "card-text-line",
  props: ["icon", "text", "value"],
}
</script>

<style scoped>

</style>
