import { memoizeDebounce } from "@/utils/memoize-debounce"
import {
  ref,
  reactive,
  computed,
  unref,
  watch,
  watchEffect,
} from "@vue/composition-api"
import equal from "fast-deep-equal"

export function useEqualWatch(items, cb, opts) {
  watch(items, (newValue, oldValue) => {
    if (equal(newValue, oldValue)) return
    cb(newValue, oldValue)
  }, opts)
}

export function useSubmittedValue(questionsMap) {
  return computed(function () {
    let items = getSubmittedValue(questionsMap.value)
    return items.filter(it => !Array.isArray(it))
  })
}

export function getSubmittedValue(questionsMap) {
  let value = []
  for (let data of Object.values(questionsMap)) {
    value.push(data)
  }

  return value.filter(it => it != null)
}

export function getForId(questions, id) {
  return questions?.find((it) => {
    return it.id === id
  })
}

export function useQuestion(questions, map) {
  let data = ref({})

  watch([questions, () => map], ([questions, map]) => {
    data.value = getQuestion(questions, map)
  }, { immediate: true })

  return data
}

export function getQuestion(questions, map) {
  if (questions == null) return {}

  let data = {}
  let handledIds = new Set()
  questions = questions.map((it) => ({
    ...it,
    id: String(it.id)
  }))

  for (let [id, prop] of Object.entries(map)) {

    handledIds.add(id)
    let item = getForId(questions, id)
    if (item != null) {
      let existingData = data[prop]
      data[prop] = {...existingData, ...item}
    }
  }

  data.unhandledQuestions = questions.filter(it => !handledIds.has(it.id)) ?? []

  return data
}

export default {
  data() {
    return {
      handledIds__: new Set(),
    }
  },
  computed: {
    unhandledQuestions() {
      let ids = [...this.handledIds__]
      return this.value?.filter((q) => !ids.includes(q.id)) ?? []
    },
  },
  methods: {
    getForId(id) {
      this.handledIds__.add(id)
      let item = this.value?.find((it) => it.id === id)
      return item
    },
    getForId$(id) {
      const that = this
      let item = this.getForId(id)

      try {
        let proxy = new Proxy(item, {
          set(target, property, value) {
            target[property] = value
            that.setForId(target.id, target)

            return true
          },
        })
        return proxy
      } catch (_) {
        return item
      }
    },
    setForId(id, v) {
      let index = this.value?.findIndex((it) => it.id === v.id)
      if (index != null && index !== -1) {
        let newObject = Object.assign({}, this.value[index], v)
        this.$set(this.value, index, newObject)
        this.$emit("input", this.value)
        console.log("set items:", v)
      }
    },
    getAnswerForId(id) {
      let item = this.getForId(id)
      return item?.answer
    },
    setAnswerForId: memoizeDebounce(function (id, answer) {
      if (answer == null) return
      let index = this.value?.findIndex((it) => it.id === id)
      if (index != null && index !== -1) {
        let newObject = Object.assign({}, this.value[index], { answer })
        this.$set(this.value, index, newObject)
        this.$emit("input", this.value)
      }
    }, 150),
    hasId(id) {
      if (this.value == null) return false
      return this.value?.find((it) => it.id === id) != null
    },
    lower(str) {
      return str?.toLowerCase()
    },
  },
}
