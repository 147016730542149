<script>
import { Line, mixins } from "vue-chartjs"

export default {
  name: "Charts",
  extends: Line,
  // mixins: [mixins.reactiveProp],
  props: ["chartData"],
  watch: {
    chartData() {
      console.log("Update charts")
      // this.$data._chart.update();
      this.render()
    },
  },
  methods: {
    render() {
      this.renderChart(this.chartData, {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          gridLines: {
            display: false,
          },
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                suggestedMax: 10,
                suggestedMin: 0,
              },
            },
          ],
        },
      })
    },
  },
  mounted() {},
}
</script>

<style scoped></style>
